import React, {useState} from 'react';
import './about-us.css'
import image3 from '../../../assets/images/image_2.png';
import {Spinner} from "../../spinner/spinner";

const AboutUs = () => {
    const [isLoading, setLoading] = useState(true);

    return (
        <section>
            <div className="about-us__content section-content">
                {isLoading ? <Spinner /> : null}

                <img
                    className={`about-us__image content-image mr-20 ${isLoading && 'loading'}`} src={image3}
                    onLoad={() => setLoading(false)}/>
                <div className="about-us__text-content">
                    <h3 className="about-as__subtitle">BTC-Solutions</h3>
                    <p className="about-us__text mb-40">Команда опытных инженеров и отраслевых экспертов, а также
                        новейшее
                        оборудование и технологии.</p>
                    <p className="about-us__text">Наша миссия - помогать клиентам достигать поставленных целей в
                        совершенствовании и оптимизации
                        производства, привлекая лучших инженеров, современное оборудование, технологии и
                        индивидуальный
                        подход.</p>
                </div>
            </div>

            <div className="section-content">
                <div className="description__paragraph paragraph">
                    <div className="icon vector-one mr-20"/>
                    <p className="text">Команда, готовая, при необходимости, выехать на объект, проанализировать
                        потребность совместно с производственными и ремонтными подразделениями клиента, а также
                        спроектировать необходимую деталь/узел персонально под ваши нужды, работать на результат.</p>
                </div>

                <div className="description__paragraph paragraph">
                    <div className="icon vector-two mr-20"/>
                    <div>
                        <p className="text mb-30">Современная производственная площадка с набором оборудования,
                            достаточным
                            для осуществления широкого спектра работ в т.ч.:</p>
                        <ul className="description__list text ml-20">
                            <li>Токарной, фрезерной, электроэрозионной обработки</li>
                            <li>Лазерной, гидроабразивной резки</li>
                            <li>Сварочных работ (полуавтоматическая, аргонодуговая, контактная, лазерная)</li>
                            <li>Шлифовальных работ</li>
                            <li>Зубообработки</li>
                            <li>Глубокого сверления</li>
                            <li>Слесарных и сварочных работ</li>
                            <li>Термообработки, окраски, гибки, пайки, резки</li>
                            <li>Лазерного наплавления, нанесения гальванических покрытий/напылений</li>
                            <li>Вулканизация полиуретана, силикона, резины</li>
                            <li>3D печати</li>
                        </ul>
                    </div>
                </div>

                <div className="description__paragraph paragraph">
                    <div className="icon vector-three mr-20"/>
                    <p className="text">Успешные реализованные проекты и долгосрочные контракты с лидерами
                        рынка производства табака и других индустрий</p>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;