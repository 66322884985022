import React, {useEffect, useState} from 'react';
import {Landing} from "./landing/landing";
import {Route, Switch} from "react-router-dom";
import AboutUs from "./about-us/about_us";
import {Services} from "./services/services";
import {Hardware} from "./hardware/hardware";
import {Works} from "./works/works";
import {Contacts} from "./contacts/contacts";

export const Main = () => {

    return (
        <main>

            <Switch>
                <Route path='/' exact component={Landing}/>
                <Route path='/about-us' component={AboutUs}/>
                <Route path='/services' component={Services}/>
                <Route path='/hardware' component={Hardware}/>
                <Route path='/works' component={Works}/>
                <Route path='/contacts' component={Contacts}/>
            </Switch>
        </main>
    );
};