import React, {useState} from 'react';
import image5 from '../../../assets/images/image_5.jpg'
import {Spinner} from "../../spinner/spinner";

export const Works = () => {
    const [isLoading, setLoading] = useState(true);
    return (
        <section className="our-works">
            <div className="our-works__content section-content">
                {isLoading && <Spinner />}
                <img
                    className={`our-works__image content-image ${isLoading && 'loading'}`}
                    src={image5}
                    onLoad={() => setLoading(false)}/>
            </div>
        </section>
    );
};