import React from 'react';
import './contacts.css'

export const Contacts = () => {
    return (
        <section className="contacts">
            <div className="contacts__content section-content">
                <div className="contacts__row">
                    <div className="contacts__avatar contacts__avatar_two mr-20"/>
                    <div className="contacts__block">
                        <p className="contacts__text white-color">
                            Федосовский Евгений Михайлович
                            +7 911 960 06 60
                            efedosovsky@btc-s.ru
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};
