import React from 'react';
import cls from './landing.module.css'
import headerImg from "../../../assets/images/image_1.png";
import headerImg3 from "../../../assets/images/image_3.png";

export const Landing = () => {
    return (
        <section className="section-content">
            <img className={cls.contentImage} src={headerImg}/>
            <div className={`${cls.titleBlock} full-center-flex`}>
                <h1 className={`${cls.title} white-color`}>
                    Индивидуальный подход для снижения ваших затрат и достижения необходимых результатов
                </h1>
            </div>

            <div>
                <img className={cls.contentImage} src={headerImg3} />
            </div>
        </section>
    );
};