import React from 'react';
import cls from'./header.module.css'
import {Link, NavLink} from "react-router-dom";

export const Header = () => {
    return (
        <header>
            <nav className={cls.navbar}>
                <div className={cls.navbarContent}>
                    <Link to='/'><div className='logo'/></Link>
                    <ul className={cls.navlist}>
                        <li className={cls.navlistItem}><NavLink to='/about-us' activeClassName={cls.active}>О нас</NavLink></li>
                        <li className={cls.navlistItem}><NavLink to='/services' activeClassName={cls.active}>Услуги</NavLink></li>
                        <li className={cls.navlistItem}><NavLink to='/hardware' activeClassName={cls.active}>Наше оборудование</NavLink></li>
                        <li className={cls.navlistItem}><NavLink to='/works' activeClassName={cls.active}>Примеры работ</NavLink></li>
                        <li className={cls.navlistItem}><NavLink to='/contacts' activeClassName={cls.active}>Контакты</NavLink></li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};