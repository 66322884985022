import React from 'react';

export const Services = () => {
    return (
        <section className="section-content">
            <div className="paragraph mb-20">
                <div className="icon vector-services mr-20"/>
                <p className="text">Реализация инженерных решений (в частности: машинное зрение, детекторы
                    нетабачных материалов, системы диагностики/измерений, автоматизация и прочее)</p>
            </div>

            <div className="paragraph mb-20">
                <div className="icon vector-services mr-20"/>
                <p className="text">Успешные реализованные проекты и долгосрочные контракты с лидерами
                    рынка производства табака и других индустрий</p>
            </div>

            <div className="paragraph mb-20">
                <div className="icon vector-services mr-20"/>
                <p className="text">Восстановление/упрочнение деталей и узлов с использованием лазерной наплавки</p>
            </div>

            <div className="paragraph mb-20">
                <div className="icon vector-services mr-20"/>
                <p className="text">Нанесение износостойких/декоративных покрытий</p>
            </div>

            <div className="paragraph mb-20">
                <div className="icon vector-services mr-20"/>
                <p className="text">Изготовление полиуретановых/резиновых/силиконовых деталей, покрытий</p>
            </div>

            <div className="paragraph mb-20">
                <div className="icon vector-services mr-20"/>
                <p className="text">Изготовление твердосплавных ножей</p>
            </div>

            <div className="paragraph mb-20">
                <div className="icon vector-services mr-20"/>
                <p className="text">Ремонт электрического оборудования (в т.ч. Капитальные ремонты
                    электродвигателей, мотор
                    роликов, приводов, охлаждающих/нагревательных систем, воздуходувок)s</p>
            </div>

            <div className="paragraph mb-20">
                <div className="icon vector-services mr-20"/>
                <p className="text">Заправка и ремонт лазеров</p>
            </div>
        </section>
    );
};