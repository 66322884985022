import React, {useState} from 'react';
import image4 from '../../../assets/images/image_4.jpg';
import {Spinner} from "../../spinner/spinner";

export const Hardware = () => {
    const [isLoading, setLoading] = useState(true);
    return (
        <section className="section-content">
            {isLoading && <Spinner/>}
            <img
                className={`content-image ${isLoading && 'loading'}`}
                src={image4}
                onLoad={() => setLoading(false)}/>
        </section>
    );
};