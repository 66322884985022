import React from 'react';
import cls from './footer.module.css'

export const Footer = () => {
    return (
        <footer className={cls.footer}>
            <div className={cls.navbar}>
                <div className={cls.navbarContent}>
                    <p className={cls.text}>btc-solutions</p>
                </div>
            </div>
        </footer>
    );
};